import React from "react"
import Layout from "../../components/Layout/Layout"
import Header from "../../components/Layout/Header"
import Seo from "../../components/Layout/Seo"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

export default function index({ pageContext }) {
    const {
        breadcrumb: { crumbs },
    } = pageContext
    return (
        <Layout>
            <Header />
            <div className="max-w-screen-md m-auto py-16 px-6 min-h-screen">
                <div className="mb-12">
                    <Breadcrumb
                        crumbs={crumbs}
                        crumbSeparator=" / "
                        crumbLabel={"ohmycottieのレシピについて"}
                    />
                </div>
                <div className="flex ">
                    <h1 className="text-2xl markerTitle inline-block text-center m-auto font-gothic">ohmycottieのレシピについて</h1>
                </div>

                <div className="mt-12 entry-content">
                    <p className="mb-4">
                        ohmycottieのレシピを美味しく安全に楽しんでいただく為に、以下の内容をよく読み、健康と安全に十分配慮した上で料理を行なってください。
                    </p>
                    <p className="mb-8">
                        調理中の火の取り扱いや包丁、ハサミなどの刃物の取り扱いには十分ご注意いただき、食中毒等の健康被害を防ぐため、食品の取り扱い・保管方法は正しく行ってください。
                    </p>
                    <h2 className="text-xl font-bold mb-4 pt-8  border-b pb-2">衛生面</h2>
                    <p className="mb-4">
                        料理をする前は手洗い用洗剤を利用し、しっかりと手を洗いましょう。また、調理中も手や調理器具の衛生に気を配りましょう。
                    </p>
                    <h2 className="text-xl font-bold mb-4 pt-8 border-b pb-2">健康と安全について</h2>
                    <h3 className="font-bold mb-4 pt-8">妊娠・授乳期間中、また乳幼児の食品摂取</h3>
                    <p className="mb-4">
                        ohmycottieで紹介しているレシピは、健康状態が良好な方を対象・想定しております。<br />レシピによってははちみつや黒糖などの自然食品を始めとした、乳幼児が摂取してはいけない食材なども使用しております。<br />小さなお子様向けに作る場合は、使用食材、加熱具合、食材の大きさなど、月齢・年齢に合った調理方法を選択していただき、場合によっては本サイト掲載のレシピでの調理をお控えください。
                    </p>
                    <h3 className="font-bold mb-2 pt-8">食物アレルギー</h3>
                    <p className="mb-4">
                        食物アレルギーをお持ちの方は、本サイト掲載のレシピにおける食材利用に十分ご注意ください。また、医師による食事制限などがある方はそれを遵守し、不安がある場合は本サイトに掲載している料理の接種をやめるか、かかりつけの医師にご相談ください。
                    </p>
                    <h3 className="font-bold mb-2 pt-8">食品の保存・保管</h3>
                    <p className="mb-8">
                        瓶やタッパーなどの容器へ食品を保存する際は、予め熱湯消毒した容器・器具を使用しましょう。また、保存した食品を取り分ける際に使う箸やスプーンなどは、必ず綺麗なものを使用してください。<br />消毒していない容器での保存や、使用済み器具での取り分けを行った場合、たとえ冷蔵庫保管をしていてもカビや雑菌が繁殖しやすくなり、食中毒の原因となります。また、保管した食品は早めに食べきるようにし、変色や異臭、カビのようなものが見られた場合には食べずに処分してください。
                    </p>

                    <h3 className="font-bold mb-2 pt-8">ハーブ・スパイス類の摂取</h3>
                    <p className="mb-8">
                        本サイトではハーブやスパイスを使用したレシピを掲載しております。ハーブやスパイスにはリラックス効果や抗ウィルス作用などを始めとした効果が期待できる一方、妊娠・授乳期間中の方や乳幼児の摂取を控えたほうが良いものもあります。これら食品の摂取において不安のある方は、事前に医師へご相談いただくか、摂取をお控えください。
                    </p>
                    <p className="mb-8">
                        また、ハーブやスパイスは健康促進の効果が期待できますが、薬ではありません。本サイト上でご紹介する食品において健康促進効果に触れていても、特別な記載が無い限りは医薬品と同じ効果を保証しているものではありません。
                    </p>
                    <h2 className="text-xl font-bold mb-4 pt-8  border-b pb-2">免責事項</h2>
                    <p className="mb-4">
                        本サイトでは、食品の取り扱い・摂取、調理時の安全において必要な注意喚起を行っておりますが、本サイトのレシピや記事の内容を実践したことによる健康被害等においては一切の責任を負いかねます。
                    </p>

                </div>
            </div>
        </Layout>
    )
}

export const Head = () => <Seo title={"ohmycottieのレシピについて"} description={"ohmycottieのレシピを安心・安全に楽しんでいただくために下記内容をよくお読みください。"} />
